import React, { Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import UseToken from './hooks/UseToken'
import 'react-notifications/lib/notifications.css'
import { NotificationContainer } from 'react-notifications'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const SendRecoveryPassword = React.lazy(() =>
  import('./views/pages/forget-password/SendRecoveryPassword'),
)
const RegeneratePassword = React.lazy(() =>
  import('./views/pages/forget-password/RegeneratePassword'),
)

const App = () => {
  const { setToken } = UseToken()
  const tokenString = sessionStorage.getItem('token')

  if (!tokenString) {
    if (window.location.pathname !== '/') {
      window.open('/', '_self')
    }
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/" element={<Login setToken={setToken} />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/send-recovery-password" element={<SendRecoveryPassword />} />
            <Route exact path="/regenerate-password" element={<RegeneratePassword />} />
          </Routes>
          <NotificationContainer />
        </Suspense>
      </HashRouter>
    )
  }
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Admin Sirvent Productes" element={<DefaultLayout />} />
        </Routes>
        <NotificationContainer />
      </Suspense>
    </HashRouter>
  )
}

export default App
