import { useState } from 'react'

const useToken = () => {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token')
    const userToken = JSON.parse(tokenString)
    return userToken?.token
  }
  const [token, setToken] = useState(getToken())
  const saveToken = (user) => {
    sessionStorage.setItem('user_id', JSON.stringify(user.id))
    sessionStorage.setItem('token', JSON.stringify(user.remember_token))
    sessionStorage.setItem('username', JSON.stringify(user.name + ' ' + user.lastname))
    sessionStorage.setItem('email', JSON.stringify(user.email))
    sessionStorage.setItem('role_id', JSON.stringify(user.role_id))
    sessionStorage.setItem('worker_code', JSON.stringify(user.worker_code))
    sessionStorage.setItem('color', JSON.stringify(user.color))
    sessionStorage.setItem('type_id', JSON.stringify(user.type_id))
    sessionStorage.setItem('client_id', JSON.stringify(user.client_id))
    setToken(user.remember_token)
  }
  return {
    setToken: saveToken,
    token,
  }
}

export default useToken
