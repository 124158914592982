import { createSlice } from '@reduxjs/toolkit'

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    sidebarShow: true,
    cart: [],
  },
  reducers: {
    addToCart: (state, action) => {
      const itemInCart = state.cart.find((item) => item.id === action.payload.id)
      if (itemInCart) {
        itemInCart.units++
      } else {
        state.cart.push({ ...action.payload, units: 1 })
      }
    },
    incrementUnits: (state, action) => {
      const item = state.cart.find((item) => item.id === action.payload)
      item.units++
    },
    decrementUnits: (state, action) => {
      const item = state.cart.find((item) => item.id === action.payload)
      if (item.units === 1) {
        item.units = 1
      } else {
        item.units--
      }
    },
    removeItem: (state, action) => {
      state.cart = state.cart.filter((item) => item.id !== action.payload)
    },
    removeCart: (state) => {
      state.cart = []
    },
    changeSidebar: (state = state.initialState, action) => {
      switch (action.payload.type) {
        case 'set':
          state.sidebarShow = action.payload.value
          return state
        default:
          return state
      }
    },
  },
})

export const cartReducer = cartSlice.reducer
export const { addToCart, incrementUnits, decrementUnits, removeItem, removeCart, changeSidebar } =
  cartSlice.actions
